<template>
<div>
  <list-page
      :columns="columns"
      :headers="headers"
      :popupComponent="popupComponent"
      :basic-data="basicData"
      :show-mail-box-news-letter="true"
      sort="email"
      title="Agency Email List"
      icon="mdi-alpha-a-circle"
      addButtonText="Email"
      stateendpoint="mailBox.mailBoxes"
      :show-select="true"
      :actions="actions"
      :param1="3"
      :action-buttons="actionButton"
  />
</div>
</template>

<script>
import ListPage from "../components/ListPage";
import MailBoxPopup from "../components/popups/MailBoxPopup";
import UserMixin from "../components/mixins/UserMixin";
import User from "../services/User";
import pTable from "../json/pTable.json";
export default {
  name: "MailBoxAgencyClient",
  mixins: [UserMixin],
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Mail Box Agency Client | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    ListPage,
    MailBoxPopup
  },
  data() {
    return {
      headers: [
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Country',
          value: 'country'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'email'},
        {value: 'isActive'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadMailBoxes',
        create: 'storeMailBox',
        update: 'updateMailBox',
        destroy: 'destroyMailBox',
        remove: 'mailBoxSoftDeleteHandler',
        sendMail: 'sendNewsLetter'
      },
      actionButton: [
        {
          category: 'timeLiner',
          icon: 'mdi-eye',
          color: '',
          text: 'View Mail Details',
          clickHandler: 'viewEmailDetails'
        }
      ],
      basicData: {
        isActive: true,
        mailType: 3
      }
    }
  },
  mounted() {
    User.auth()
    .then(({data})=> {
      if(!(pTable.settings.mailBox.find(item => item === data.email))) {
        this.$router.push({path: '/'});
      }
    })
    .catch(error=> {
      this.$router.push({path: '/'});
    })
  },
  computed: {
    popupComponent() {
      return MailBoxPopup;
    }
  }
}
</script>

<style scoped>

</style>